export const LiveIcon: React.VFC<{
  style?: React.CSSProperties;
  className?: string;
}> = ({ style, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 227.63 95.6"
      style={style}
      className={className}
    >
      <path
        d="M227.63,0H0V95.6H227.63ZM66.33,72.48a4.54,4.54,0,0,1-3.24,1.23H39a4.44,4.44,0,0,1-4.54-4.54V26.23A4.32,4.32,0,0,1,35.75,23a4.68,4.68,0,0,1,3.42-1.3A4.46,4.46,0,0,1,42.33,23a4.27,4.27,0,0,1,1.37,3.23V65H63.09a4.54,4.54,0,0,1,3.24,1.23,4.18,4.18,0,0,1,1.3,3.16A4.08,4.08,0,0,1,66.33,72.48Zm23.59-3.31a4.24,4.24,0,0,1-1.42,3.24,4.71,4.71,0,0,1-3.27,1.3,4.38,4.38,0,0,1-4.6-4.54V26.23A4.28,4.28,0,0,1,82,23a4.68,4.68,0,0,1,3.41-1.3A4.46,4.46,0,0,1,88.54,23a4.24,4.24,0,0,1,1.38,3.23Zm57.73-41.31-18.36,43A4.56,4.56,0,0,1,127.4,73a5,5,0,0,1-2.49.71,4.83,4.83,0,0,1-2.3-.78,4.4,4.4,0,0,1-1.71-2.05L102.55,27.79a3.16,3.16,0,0,1-.3-.93,6,6,0,0,1-.08-.93,3.85,3.85,0,0,1,1.56-3.3,5.09,5.09,0,0,1,3-1.16,4.24,4.24,0,0,1,4.08,2.9l14.93,35.11L139.4,24.37c1.06-2.38,2.95-3.25,5.68-2.63,2,.91,3,2.29,3,4.12A5.17,5.17,0,0,1,147.65,27.86Zm38.78,15a4.52,4.52,0,0,1,3.23,1.23,4.14,4.14,0,0,1,1.3,3.16,3.84,3.84,0,0,1-1.3,3,4.73,4.73,0,0,1-3.23,1.15H169.64V65.16h20.13a4.43,4.43,0,0,1,3.24,1.3,4.16,4.16,0,0,1,1.3,3,4,4,0,0,1-1.3,3,4.64,4.64,0,0,1-3.24,1.19H164.88a4.44,4.44,0,0,1-4.53-4.54V26.23a4.44,4.44,0,0,1,4.53-4.53h24.89A4.58,4.58,0,0,1,193,22.92a4.18,4.18,0,0,1,1.3,3.16,3.85,3.85,0,0,1-1.3,3,4.71,4.71,0,0,1-3.24,1.15H169.64V42.87Z"
        fill="currentColor"
      />
    </svg>
  );
};
