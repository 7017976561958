import { useEffect, useRef, useMemo } from "react";
import styled from "styled-components";
import FontFaceObserver from "fontfaceobserver";

// components
import { Twemoji } from "./modules/Twemoji";
import { EffectedContainer } from "./EffectedContainer";
import { AvatarVisual } from "./AvatarVisual";

// others
import { LiveDataComplete } from "../types";
import { videoAspectRatio } from "../utils/constants";
import { getBackgroundStyles } from "../utils/helper";

const font = new FontFaceObserver("M PLUS Rounded 1c", { weight: 700 }); // canvasに描画する前にフォントファイルを読み込んでおく
const ctxWidth = 1000;
const ctxHeight = ctxWidth * videoAspectRatio;
const lineHeight = 1.05;

type CameraViewProps = {
  liveData: LiveDataComplete;
  avatarUrl?: null | string;
};

export const CameraView: React.VFC<CameraViewProps> = ({
  liveData,
  avatarUrl,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const {
    textColor,
    textSize,
    emojiSize,
    emoji,
    text,
    background,
    activeContentType,
    effect,
  } = liveData;

  const backgroundStyles = useMemo(() => {
    return getBackgroundStyles(background);
  }, [background]);

  function renderTextToCanvas(text: string) {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");
    if (!ctx) return;
    ctx.clearRect(0, 0, ctxWidth, ctxHeight); // 既存の描画をリセット

    ctx.beginPath();
    ctx.fillStyle = textColor;
    ctx.font = `700 ${textSize}px 'M PLUS Rounded 1c'`;

    const lines = text.split("\n");
    const rows = lines.length;
    const totalTextHeight =
      textSize / 2 + textSize * rows + lineHeight * (rows - 1); // 合計のテキストの高さ
    const startY = (ctxHeight - totalTextHeight) / 2;

    lines.forEach((line, i) => {
      const lineX = (ctxWidth - ctx.measureText(line).width) / 2;
      const addY = i === 0 ? textSize : textSize * lineHeight * (i + 1);
      const lineY = startY + addY; // (startY / 5) は少し上に表示するための微調整
      ctx.fillText(
        line,
        lineX > 0 ? lineX : 0,
        lineY > 0 ? lineY : 0,
        ctxWidth
      );
    });
  }

  useEffect(() => {
    if (activeContentType !== "text") return;
    if (typeof text !== "string") return;

    font.load().then(() => {
      renderTextToCanvas(text);
    });
  }, [liveData]);

  return (
    <Container style={backgroundStyles}>
      {!!avatarUrl && (
        <AvatarContainer>
          <AvatarVisual avatarUrl={avatarUrl} />
        </AvatarContainer>
      )}
      <EffectedContainer effectType={effect}>
        {activeContentType === "emoji" ? (
          <Twemoji text={emoji} size={`${emojiSize}%`} />
        ) : (
          <StyledCanvas ref={canvasRef} width={ctxWidth} height={ctxHeight} />
        )}
      </EffectedContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const StyledCanvas = styled.canvas`
  width: 100%;
  letter-spacing: -0.1em;
`;

const AvatarContainer = styled.div`
  position: absolute;
  left: 3%;
  top: 4%;
  width: 12%;
`;
