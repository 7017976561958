import { initializeApp, getApp, getApps } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import type { FirebaseApp } from "firebase/app";

// NOTE: 以下のfirebaseの環境変数はすべて公開可能
// NOTE: development環境を別にデプロイする場合は環境変数化する
// catnose99@gmail.com のGoogleアカウントで以下のFirebaseプロジェクトを作成
const firebaseConfig = {
  apiKey: "AIzaSyCrZcBxiSSMFuLTvoiDPhfd2N-kfXmXLSM",
  authDomain: "no-camera-meeting.firebaseapp.com",
  projectId: "no-camera-meeting",
  storageBucket: "no-camera-meeting.appspot.com",
  messagingSenderId: "75517644073",
  appId: "1:75517644073:web:2b83a73763d7c277abbb38",
  measurementId: "G-RY9LGVYVL2",
};

export const app: FirebaseApp = getApps().length
  ? getApp()
  : initializeApp(firebaseConfig);

getAnalytics();
