// NOTE:このコンポーネントはdynamicで読み込むこと（SSRだとおかしくなる）
import { memo } from "react";
import twemoji from "twemoji";
import styled from "styled-components";

type Props = {
  text?: string;
  className?: string;
  emojiClassName?: string;
  style?: React.CSSProperties;
  size: string;
};

export const Twemoji: React.FC<Props> = memo(
  ({ text, className, emojiClassName, style, size }) => {
    if (!text?.length) return null;

    return (
      <Content
        dangerouslySetInnerHTML={{
          __html: twemoji.parse(text, {
            folder: "svg",
            ext: ".svg",
            className: emojiClassName,
            // CloudflareのCDNから配信されるファイルをありがたく使わせてもらう
            // ref: https://cdnjs.com/libraries/twemoji/14.0.2
            base: `https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/`,
          }),
        }}
        style={{
          width: size,
          height: size,
          ...(style || {}),
        }}
        className={className}
      />
    );
  }
);

const Content = styled.span`
  display: inline-flex;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
  }
`;
