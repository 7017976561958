import styled, { keyframes } from "styled-components";
import { EffectType } from "../types";

type EffectedContainerProps = {
  children: React.ReactNode;
  effectType: EffectType;
  style?: React.CSSProperties;
};

export const EffectedContainer: React.VFC<EffectedContainerProps> = ({
  effectType,
  children,
  style,
}) => {
  return (
    <Container className={effectType} style={style}>
      {children}
    </Container>
  );
};

const bounce = keyframes`
	0%, 100% {
    transform: scale(1) translateY(10%);
  }
	30% {
    transform: scale(1) translateY(-20%);
  }
	60% {
    transform: scale(1) translateY(10%);
  }
	90% {
    top: 0;
    transform: scale(1.2,0.85) translateY(10%);
  }
}
`;
const shake = keyframes`
  0% {
    transform: translate(0, 0)
  }
  50% {
    transform: translate(1em, 0)
  }
  100% {
    transform: translate(0, 0)
  }
`;

const purupuru = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3)
  }
`;
const nobi = keyframes`
  0%, 100% {
      transform: scale(1.4, 0.5);
  }
  33% {
      transform: scale(0.5, 1.4);
  }
  66% {
      transform: scale(1, 1);
  }
`;
const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;
const flash = keyframes`
  50% {
    opacity: 0
  }
`;
const slide = keyframes`
  0% {
    transform: translateX(102%)
  }
  100% {
    transform: translateX(-102%)
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &.bounce {
    animation: ${bounce} 1.2s infinite;
  }
  &.shake {
    animation: ${shake} 0.1s infinite;
  }
  &.purupuru {
    animation: ${purupuru} 0.2s infinite;
  }
  &.nobi {
    animation: ${nobi} 9s linear infinite;
  }
  &.rotate {
    animation: ${rotate} 5s linear infinite;
  }
  &.flash {
    animation: ${flash} 0.6s linear infinite;
  }
  &.slide {
    animation: ${slide} 4s linear infinite;
  }
`;
