import { memo } from "react";

type SizeValue = string | number;

type Props = {
  x?: SizeValue;
  y?: SizeValue;
  inline?: boolean;
};

function getSize(value?: SizeValue) {
  if (value === undefined) return `1px`;
  if (typeof value === "number") return `${value}px`;
  return value;
}

export const Spacer: React.VFC<Props> = memo(({ x, y, inline }) => {
  return (
    <span
      style={{
        display: inline ? "inline-block" : "block",
        width: getSize(x),
        height: getSize(y),
        flexShrink: 0,
      }}
    />
  );
});
