type ContentWrapperProps = {
  children: React.ReactNode;
  width?: number;
  padding?: string;
};

export const ContentWrapper: React.VFC<ContentWrapperProps> = ({
  width = 640,
  children,
}) => {
  return (
    <div
      style={{
        width,
        maxWidth: "100%",
        padding: "0 18px",
        margin: "0 auto",
      }}
    >
      {children}
    </div>
  );
};
