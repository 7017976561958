import styled from "styled-components";
import { useParams } from "react-router-dom";

// components
import { CameraView } from "../CameraView";
import { Error } from "../Error";

import { useRoom } from "../../utils/firebase/db";

const Room: React.VFC = () => {
  let { id } = useParams<{ id: string }>();
  const { room, errorCode } = useRoom(id);

  if (errorCode) return <Error statusCode={errorCode} />;

  if (!room) return null;

  return (
    <Container>
      <CameraView liveData={room.liveData} avatarUrl={room.avatarUrl} />
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  height: 100vh;
`;

// React.lazyで読み込むためにdefault exportする必要あり
export default Room;
