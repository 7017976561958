import validateColor from "validate-color";
import { effectTypes, defaultEditorOptions } from "./constants";
import {
  LiveDataComplete,
  EditorOptionsComplete,
  EffectType,
  ColorOption,
  Room,
} from "../types";

function nomalizeEffectTypes(value?: unknown): EffectType {
  if (typeof value !== "string") return "none";
  if (effectTypes.includes(value as EffectType)) return value as EffectType;
  return "none";
}

export function isObject(value: unknown): value is { [key: string]: unknown } {
  return typeof value === "object" && value !== null;
}

export function normalizeLiveData(liveData: unknown): LiveDataComplete {
  const data = isObject(liveData) ? liveData : {};

  const {
    activeContentType,
    text,
    emoji,
    emojiSize,
    textSize,
    textColor,
    background,
    effect,
  } = data;

  return {
    activeContentType: activeContentType === "emoji" ? "emoji" : "text",
    text: typeof text === "string" ? text : "Hello!",
    emoji: typeof emoji === "string" ? emoji : "😸",
    emojiSize: typeof emojiSize === "number" ? emojiSize : 35,
    textSize: typeof textSize === "number" ? textSize : 160,
    textColor:
      typeof textColor === "string" && validateColor(textColor)
        ? textColor
        : "#FFF",
    effect: nomalizeEffectTypes(effect),
    background: typeof background === "string" ? background : "",
  };
}

function normalizeEmojis(value: unknown): string[] {
  if (!Array.isArray(value)) return defaultEditorOptions.emojis;
  return value.filter((item) => typeof item === "string");
}

function normalizeMessages(value: unknown): string[] {
  if (!Array.isArray(value)) return defaultEditorOptions.messages;
  return value.filter((item) => typeof item === "string");
}

export function isValidColorOption(value: unknown): value is ColorOption {
  if (!isObject(value)) return false;
  return (
    value.hasOwnProperty("color") &&
    typeof value["color"] === "string" &&
    value.hasOwnProperty("background") &&
    typeof value["background"] === "string"
  );
}

export function isArrayOfString(value: unknown): value is string[] {
  if (!Array.isArray(value)) return false;
  return value.every((item) => typeof item === "string");
}

function normalizeColors(value: unknown): ColorOption[] {
  if (!Array.isArray(value)) return defaultEditorOptions.colors;
  return value.filter((item) => isValidColorOption(item));
}

export function normalizeEditorOptions(
  editorOptions: unknown
): EditorOptionsComplete {
  if (!isObject(editorOptions)) return defaultEditorOptions;
  const emojis = editorOptions.hasOwnProperty("emojis")
    ? normalizeEmojis(editorOptions.emojis)
    : defaultEditorOptions.emojis;
  const messages = editorOptions.hasOwnProperty("messages")
    ? normalizeMessages(editorOptions.messages)
    : defaultEditorOptions.messages;
  const colors = editorOptions.hasOwnProperty("colors")
    ? normalizeColors(editorOptions.colors)
    : defaultEditorOptions.colors;

  return {
    emojis,
    messages,
    colors,
  };
}

export function normalizeRoom(value: unknown): Room {
  if (!isObject(value)) throw new Error("不適切なRoomのデータが取得されました");

  return {
    liveData: normalizeLiveData(value.liveData),
    editorOptions: normalizeEditorOptions(value.editorOptions),
    avatarUrl: typeof value.avatarUrl === "string" ? value.avatarUrl : null,
  };
}
