import styled from "styled-components";
import { Link } from "react-router-dom";

import { Twemoji } from "../modules/Twemoji";

export const HomeLink: React.VFC = () => {
  return (
    <StyledLink to="/">
      <Twemoji size="27px" text="🎙" className="icon" />
      <img src="/logo.svg" alt="Emoji Live" width={121} height={26} />
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  margin: 0;
  font-size: 1.8rem;
  display: inline-flex;
  align-items: center;
  .icon {
    margin-right: 0.3em;
  }
`;
