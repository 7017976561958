import { createPortal } from "react-dom";
const targetId = `modal-portal`;

// app.tsxなどのアプリ全体で読み込まれる部分に配置する
export const ModalPortalTarget: React.VFC = () => <div id={targetId}></div>;

export const ModalPortal: React.VFC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const el = document.getElementById(targetId);
  if (!el)
    throw new Error(
      "<ModalPortalTarget /> を </body> の直前に配置してください"
    );
  return createPortal(children, el);
};
