import validateColor from "validate-color";

export function isAppleOs(): boolean {
  return !!navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i);
}

export function getBackgroundStyles(background?: string): React.CSSProperties {
  const fallback = {
    background: "#222",
  };

  if (!background?.length) return fallback;

  if (!background.startsWith("https") && validateColor(background)) {
    return {
      background,
    };
  }

  if (/\.(jpg|jpeg|png|gif|webp|svg)$/i.test(background))
    return {
      backgroundImage: `url(${background})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "50% 50%",
      backgroundSize: "contain",
    };

  return fallback;
}

const localMyRoomKey = "my-room";

export function locallySaveMyRoom(roomId: string) {
  localStorage.setItem(localMyRoomKey, roomId);
}

export function getLocallySavedRoom() {
  return localStorage.getItem(localMyRoomKey);
}
export function removeLocallySavedRoom() {
  localStorage.removeItem(localMyRoomKey);
}
export function removeAllLocalData() {
  localStorage.clear();
}
// オブジェクトからundefinedの値のプロパティを除く
export function deleteUndefinedFromObject<T>(obj: T) {
  Object.keys(obj).forEach((key) =>
    (obj as any)[key] === undefined ? delete (obj as any)[key] : {}
  );
}
