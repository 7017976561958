import styled, { keyframes } from "styled-components";

import { LiveIcon } from "./modules/LiveIcon";

export const AvatarVisual: React.VFC<{ avatarUrl: string }> = ({
  avatarUrl,
}) => {
  return (
    <AvatarContainer>
      <AvatarImg src={avatarUrl} alt="" />
      <LiveIcon className="live-icon" />
    </AvatarContainer>
  );
};

const AvatarContainer = styled.div`
  border-radius: 50%;
  padding: 1%;
  background: rgba(255, 255, 255, 0.3);
  position: relative;

  .live-icon {
    position: absolute;
    width: 90%;
    left: 5%;
    top: 88%;
    color: #f05757;
    background: #fff;
  }
`;

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const AvatarImg = styled.img`
  position: relative;
  width: 100%;
  height: auto;
  border-radius: 50%;
  display: block;
  animation: ${rotate} 6s linear infinite;
`;
