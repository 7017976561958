// external
import styled, { css } from "styled-components";

// Base style for all buttons
const buttonBase = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1.3;
  cursor: pointer;
  white-space: nowrap;
  border: solid 1px transparent;
  &:disabled {
    opacity: 0.5;
    cursor: initial;
  }
  svg:first-child {
    margin: 0 0.2em 0 -0.2em;
    width: 0.9em;
    height: 0.9em;
  }
`;

export const PrimaryButton = styled.button`
  ${buttonBase}
  background: var(--c-primary);
  font-weight: 700;
  border-radius: 2.5em;
  padding: 0.6em 1.2em;
  font-size: 1.05rem;
  &:hover:not(:disabled) {
    background: var(--c-primary-darker);
  }
`;
export const SecondaryButton = styled.button`
  ${buttonBase}
  background: var(--c-background-lighter);
  border-radius: 0.5em;
  padding: 0.6em 1.2em;
  font-size: 0.9rem;
  border: solid 1px rgba(255, 255, 255, 0.1);
  &:hover:not(:disabled) {
    background: var(--c-background-lightest);
  }
`;
export const TextButton = styled.button`
  ${buttonBase}
  color: var(--c-gray);
  font-size: 0.9rem;
  &:hover:not(:disabled) {
    color: var(--c-base-text);
  }
`;
