import { EditorOptionsComplete } from "../types";
// これ以下のサイズのviewportではcontrollのスタイルが崩れる
export const minViewportToCreateRoom = "600px";

export const videoAspectRatio = 0.56; // zoomは 9 : 16 っぽい？

export const defaultEditorOptions: EditorOptionsComplete = {
  emojis: [
    "🙃",
    "😀",
    "😎",
    "😊",
    "😋",
    "🤯",
    "😂",
    "🤣",
    "😆",
    "🤪",
    "🥲",
    "😭",
    "😨",
    "😇",
    "🤤",
    "🤔",
    "🎉",
    "🙏",
    "🙌",
    "👏",
    "👌",
    "🖐",
    "😺",
    "😸",
    "😹",
    "😿",
    "🐷",
    "🐣",
    "👁",
    "🗿",
    "💭",
    "💣",
    "💡",
    "㊙️",
    "🉑",
    "🆒",
    "🆗",
    "💯",
    "⁉",
    "⭕",
    "❌",
    "⚠",
  ],
  messages: [
    "ｵｯ",
    "わーい",
    "たしかに",
    "ふむふむ",
    "ﾋｪｯ…",
    "わかる",
    "わいわい",
    "ですね",
    "草",
    "それな",
    "ですよね",
    "なるほど",
    "神",
    "さすが",
    "まじすか",
    "ごめんね",
    "はい",
    "いい話",
    "ありがと",
    "しらんけど",
    "え",
    "すごい",
    "問題ない",
    "よろしく\nお願いします",
  ],
  colors: [
    { color: "#FFF", background: "#222" },
    { color: "#FFF", background: "#268ec9" },
    { color: "#FFF", background: "#dc6f34" },
    { color: "#FFF", background: "#e53935" },
    { color: "#FFF", background: "#8637b3" },
    { color: "#FFF", background: "#2e7d32" },
    { color: "#111", background: "#FFF" },
    { color: "#143d6f", background: "#b5deff" },
    { color: "#ed7a00", background: "#faddb8" },
    { color: "#e53935", background: "#ffd8d0" },
    { color: "#8637b3", background: "#f3dfff" },
    { color: "#2e7d32", background: "#dcf5e7" },
  ],
};

export const effectTypes = [
  "none",
  "bounce",
  "shake",
  "purupuru",
  "nobi",
  "rotate",
  "flash",
  "slide",
] as const;
