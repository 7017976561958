import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { useHistory, Link } from "react-router-dom";

// components
import { Spacer } from "../modules/Spacer";
import { PrimaryButton } from "../modules/Button";
import { ContentWrapper } from "../modules/ContentWrapper";
import { HomeLink } from "../modules/HomeLink";
import { LpHeroImage } from "../LpHeroImage";

// others
import { createRoom } from "../../utils/firebase/db";
import { paths } from "../../utils/paths";
import { minViewportToCreateRoom } from "../../utils/constants";
import { useLocallySavedRoom } from "../../hooks/useLocallySavedRoom";

// icons
import SvgHelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";

const Home: React.VFC = () => {
  const history = useHistory();
  const [roomCreating, setRoomCreating] = useState<boolean>(false);
  const savedRoomId = useLocallySavedRoom();

  const roomButtonText = useMemo(() => {
    if (roomCreating) return "ルームを作成中…";
    if (savedRoomId) return "ルームへ移動";
    return "ルームを作成する";
  }, [roomCreating, savedRoomId]);

  const handleClickRoomButton = useMemo(() => {
    return savedRoomId ? () => moveToRoom(savedRoomId) : handleCreateRoom;
  }, [savedRoomId]);

  function moveToRoom(roomId: string) {
    history.push(paths.roomController(roomId));
  }

  async function handleCreateRoom() {
    setRoomCreating(true);
    try {
      const roomId = await createRoom();
      if (roomId) {
        moveToRoom(roomId);
      }
    } catch (err) {
      alert("エラーが発生しました。時間を置いて再度やり直してください");
    } finally {
      setRoomCreating(false);
    }
  }

  return (
    <>
      <Header>
        <ContentWrapper width={900}>
          <HeaderInner>
            <HomeLink />
            <HeaderActions>
              <HeaderHowToUseLink href="/guide">
                <SvgHelpOutlineOutlinedIcon />
                使い方
              </HeaderHowToUseLink>
              <HeaderDesktopOnly>
                <Spacer x="25px" />
                <PrimaryButton
                  onClick={handleClickRoomButton}
                  disabled={roomCreating}
                  style={{
                    fontSize: "16px",
                    padding: "9px 14px",
                  }}
                >
                  {roomButtonText}
                </PrimaryButton>
              </HeaderDesktopOnly>
            </HeaderActions>
          </HeaderInner>
        </ContentWrapper>
      </Header>

      <ContentWrapper>
        <Spacer y="2.8rem" />
        <HeroTitle>
          カメラオフでも
          <br />
          たのしい会議
        </HeroTitle>
        <Spacer y="2rem" />
        <HeroImageContainer>
          <LpHeroImage />
        </HeroImageContainer>
        <Spacer y="1.7rem" />
        <Description>カメラの映像の代わりに絵文字を配信！</Description>
        <Description>
          オンライン会議で顔を出したくない人のための画面配信ツール
        </Description>
        <Spacer y="1.7rem" />
        <EnabledActionContainer>
          <>
            <div>
              <PrimaryButton
                onClick={handleClickRoomButton}
                disabled={roomCreating}
              >
                {roomButtonText}
              </PrimaryButton>
            </div>
            {!savedRoomId && <SpeechBlock>会員登録なしで使えます</SpeechBlock>}
          </>
        </EnabledActionContainer>
        <DisabledActionContainer>
          <PrimaryButton disabled={true}>ルームを作成する</PrimaryButton>
          <SpeechBlock>
            画面が小さすぎてルームを作成できません。
            <br />
            パソコンからアクセスしてください。
          </SpeechBlock>
        </DisabledActionContainer>
        <Spacer y="3.5rem" />
        <Footer>
          <Link to={paths.terms}>利用規約</Link>
          <Link to={paths.privacy}>プライバシーポリシー</Link>
        </Footer>
      </ContentWrapper>
    </>
  );
};

const Header = styled.header`
  background: var(--c-background);
  position: sticky;
  top: -1px;
  z-index: 99;
`;

const HeaderInner = styled.div`
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const HeaderDesktopOnly = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${minViewportToCreateRoom}) {
    display: none;
  }
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderHowToUseLink = styled.a`
  display: inline-flex;
  align-items: center;
  color: var(--c-gray);
  font-size: 16px;
  &:hover {
    color: var(--c-base-text);
  }
  svg {
    width: 0.8em;
    height: 0.8em;
    margin-right: 0.2em;
  }
`;
const HeroTitle = styled.h2`
  margin: 0;
  font-size: 2.5rem;
`;
const HeroImageContainer = styled.div`
  margin: 0 auto;
  max-width: 500px;
`;
const Description = styled.p`
  margin: 0;
  font-size: 1rem;
  line-height: 1.8;
  color: var(--c-gray);
`;

const DisabledActionContainer = styled.div`
  display: none;
  @media (max-width: ${minViewportToCreateRoom}) {
    display: block;
  }
`;

const SpeechBlock = styled.div`
  margin-top: 1.5rem;
  position: relative;
  display: inline-block;
  padding: 0.6rem 0.8rem;
  min-width: 120px;
  color: var(--c-gray);
  font-size: 14px;
  border: solid 2px var(--c-border);
  border-radius: 14px;
  line-height: 1.5;
  &:before {
    content: "";
    position: absolute;
    top: -21px;
    left: 50%;
    margin-left: -10px;
    border: 10px solid transparent;
    border-bottom: 10px solid var(--c-border);
  }
  &:after {
    content: "";
    position: absolute;
    top: -18px;
    left: 50%;
    margin-left: -10px;
    border: 10px solid transparent;
    border-bottom: 10px solid var(--c-background);
  }
`;
const EnabledActionContainer = styled.div`
  display: block;
  @media (max-width: ${minViewportToCreateRoom}) {
    display: none;
  }
`;

const Footer = styled.footer`
  margin-top: 3rem;
  padding: 0.8rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--c-gray);
  font-size: 0.9rem;
  a {
    margin: 0.5rem;
    &:hover {
      text-decoration: underline;
    }
  }
`;

// React.lazyで読み込むためにdefault exportする必要あり
export default Home;
