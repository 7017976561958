import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";

// components
import { ModalPortalTarget } from "../modules/ModalPortal";
import { Loading } from "../modules/Loading";
import { Error } from "../Error";

// pages
import Home from "./Home";
import Room from "./Room";
const RoomController = React.lazy(() => import("./RoomController"));
const RoomEdit = React.lazy(() => import("./RoomEdit"));
const Guide = React.lazy(() => import("./Guide"));
const Terms = React.lazy(() => import("./Terms"));
const Privacy = React.lazy(() => import("./Privacy"));

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffa22c",
    },
    secondary: {
      main: "#FFF",
    },
  },
});
const App = () => {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Suspense fallback={<Loading margin="4rem auto" />}>
          <Router>
            <Switch>
              <Route path={`/rooms/:id/edit`} exact>
                <RoomEdit />
              </Route>
              <Route path={`/rooms/:id/controller`} exact>
                <RoomController />
              </Route>
              <Route path={`/rooms/:id`} exact>
                <Room />
              </Route>
              <Route path={`/guide`} exact>
                <Guide />
              </Route>
              <Route path={`/terms`} exact>
                <Terms />
              </Route>
              <Route path={`/privacy`} exact>
                <Privacy />
              </Route>
              <Route path={`/`} exact>
                <Home />
              </Route>
              <Route>
                <Error statusCode={404} />
              </Route>
            </Switch>
          </Router>
        </Suspense>
        <ModalPortalTarget />
      </ThemeProvider>
    </div>
  );
};

export default App;
