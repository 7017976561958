import { memo } from "react";
import styled, { keyframes } from "styled-components";

export const Loading: React.FC<{ margin?: string }> = memo(
  ({ margin = "5rem auto" }) => {
    return <Container style={{ margin }} />;
  }
);

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  width: 30px;
  height: 30px;
  display: table;
  margin: 0 auto;
  border: 3px solid var(--c-primary);
  border-radius: 50%;
  border-top-color: transparent;
  animation: ${rotate} 0.8s linear infinite, ${fadeIn} 0.7s;
`;
