import { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { Twemoji } from "./modules/Twemoji";

import { ReactComponent as SvgHeroFrame } from "../svg/hero-frame.svg";
import { ReactComponent as SvgHello } from "../svg/hero-hello-text.svg";

export const LpHeroImage = () => {
  const [motionNumber, setMotionNumber] = useState<number>(1);

  useEffect(() => {
    // 数秒おきに表示する項目をｓ切り替える
    const timeoutId = setTimeout(() => {
      setMotionNumber(motionNumber === 4 ? 1 : motionNumber + 1);
    }, 1600);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [motionNumber]);

  return (
    <Container>
      <AnimatedArea>
        <div className="motion1" aria-hidden={motionNumber !== 1}>
          <Twemoji text="👋" size="50%" className="icon" />
        </div>
        <div className="motion2" aria-hidden={motionNumber !== 2}>
          <Twemoji text="😸" size="50%" className="icon" />
        </div>
        <div className="motion3" aria-hidden={motionNumber !== 3}>
          <Twemoji text="⁉️" size="50%" className="icon" />
        </div>
        <div className="motion4" aria-hidden={motionNumber !== 4}>
          <SvgHello className="icon" />
        </div>
      </AnimatedArea>
      <SvgHeroFrame className="svg-hero-frame" />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  .svg-hero-frame {
    position: relative;
    z-index: 1;
  }
`;

const animMotion1Icon = keyframes`
  0%, 100% { transform: rotate(0) }
  50% { transform: rotate(25deg) }
`;

const animMotion2Icon = keyframes`
  100% { transform: rotate(360deg) }
`;
const animMotion3Icon = keyframes`
  0%, 100% {
    transform: translate(0, 0)
  }
  50% {
    transform: translate(5%, 0)
  }
`;
const animMotion4Icon = keyframes`
  50% {
    opacity: 0
  }
`;

const AnimatedArea = styled.div`
  position: absolute;
  top: 11%;
  left: 36%;
  right: 38%;
  bottom: 56.5%;

  .motion1,
  .motion2,
  .motion3,
  .motion4 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease;
    visibility: hidden;
    opacity: 0;
    transform: scale(0.2);
    &[aria-hidden="false"] {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }
  }
  .motion1 {
    background: #2f3031;
    .icon {
      animation: ${animMotion1Icon} 0.3s linear infinite;
    }
  }
  .motion2 {
    background: #5ea8ff;
    .icon {
      animation: ${animMotion2Icon} 2.5s linear infinite;
    }
  }
  .motion3 {
    background: #ffc7d3;
    .icon {
      animation: ${animMotion3Icon} 0.1s linear infinite;
    }
  }
  .motion4 {
    background: var(--c-primary);
    .icon {
      width: 80%;
      height: auto;
      animation: ${animMotion4Icon} 0.8s linear infinite;
    }
  }
`;
