import { app } from "./app";
import { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  doc,
  addDoc,
  setDoc,
  onSnapshot,
} from "firebase/firestore";

import { Room, LiveData } from "../../types";
import { locallySaveMyRoom, removeLocallySavedRoom } from "../helper";
import {
  normalizeLiveData,
  normalizeEditorOptions,
  normalizeRoom,
} from "../normalizer";

export const db = getFirestore(app);

const roomCollectionName = "rooms";

export async function createRoom(): Promise<string> {
  const initialData = {
    liveData: normalizeLiveData({
      emoji: "😸",
      activeContentType: "emoji",
    }),
    editorOptions: null,
    avatarUrl: null,
  };

  const docRef = await addDoc(collection(db, roomCollectionName), initialData);
  const roomId = docRef.id;
  locallySaveMyRoom(roomId);
  return roomId;
}

export async function updateRoom(id: string, attributes: Partial<Room>) {
  await setDoc(doc(db, roomCollectionName, id), attributes, {
    merge: true,
  });
}

export async function updateLiveData(
  roomId: string,
  attributes: Partial<LiveData>
) {
  await updateRoom(roomId, {
    liveData: normalizeLiveData(attributes),
  });
}

export async function updateEditorOptions(roomId: string, attributes: unknown) {
  await updateRoom(roomId, {
    editorOptions: normalizeEditorOptions(attributes),
  });
}

export function useRoom(id: string) {
  const [room, setRoom] = useState<undefined | Room>(undefined);
  const [errorCode, setErrorCode] = useState<undefined | number>(undefined);

  useEffect(() => {
    const unsub = onSnapshot(doc(db, roomCollectionName, id), (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        if (!data.liveData) {
          // おかしなデータが保存されているのでエラーに
          setErrorCode(500);
          removeLocallySavedRoom();
          return;
        }
        setRoom(normalizeRoom(data));
      } else {
        setErrorCode(404);
      }
    });
    return () => {
      unsub();
    };
  }, [id]);

  return {
    room,
    errorCode,
  };
}
