import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { PrimaryButton } from "./modules/Button";
import { Spacer } from "./modules/Spacer";

import { paths } from "../utils/paths";

type ErrorProps = {
  statusCode: number;
};

export const Error: React.VFC<ErrorProps> = ({ statusCode }) => {
  const history = useHistory();

  const message =
    statusCode === 404
      ? "ページが見つかりませんでした"
      : "エラーが発生しました";

  return (
    <Container>
      <Content>
        <HeadingStatus>{statusCode}</HeadingStatus>
        <Spacer y="0.7rem" />
        <div>{message}</div>
        <Spacer y="1.8rem" />
        <PrimaryButton onClick={() => history.push(paths.home)}>
          ホームへ戻る
        </PrimaryButton>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Content = styled.div`
  margin-top: -1rem; // 微調整
`;
const HeadingStatus = styled.h1`
  margin: 0;
  font-size: 5rem;
  line-height: 1.1;
  letter-spacing: 0.1em;
  color: var(--c-primary);
`;
