import { useState, useEffect } from "react";
import { getLocallySavedRoom } from "../utils/helper";

export function useLocallySavedRoom() {
  const [savedRoomId, setSavedRoomId] = useState<undefined | string>(undefined);

  useEffect(() => {
    const roomId = getLocallySavedRoom();
    if (roomId) setSavedRoomId(roomId);
  }, []);

  return savedRoomId;
}
